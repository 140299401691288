<template>
  <div class="tab-parent-container">
    <b-tabs v-model="tabIndex" @activate-tab="onTabActivated" content-class="mt-3" nav-class="n-product-tab" justified>
      <b-tab active>
        <template v-slot:title>
          General
          <br />Information
          <font-awesome-icon v-if="!isGeneralInfoValid" class="n-error" icon="exclamation-circle" />
        </template>
        <template>
          <GeneralInformation :product="product" @changed="changed"></GeneralInformation>
        </template>
      </b-tab>
      <b-tab>
        <template v-slot:title>
          Custom
          <br />Information
        </template>
        <template>
          <AdditionalInformation :product="product"></AdditionalInformation>
        </template>
      </b-tab>
      <b-tab>
        <template v-slot:title>
          Orderable
          <br />Dates
        </template>
        <template>
          <ProductAvailability></ProductAvailability>
        </template>
      </b-tab>
      <b-tab :disabled="isDisabled">
        <template v-slot:title>
          Inventory
          <br />Management
        </template>
        <template>
          <InventoryManagement :defaultOutOfStockMessageText="defaultOutOfStockMessageText"></InventoryManagement>
        </template>
      </b-tab>
      <b-tab>
        <template v-slot:title>
          Product
          <br />Media
        </template>
        <template>
          <ProductMedia :imageConfig="imageConfig"></ProductMedia>
        </template>
      </b-tab>
      <b-tab :disabled="isDisabled">
        <template v-slot:title>
          Assign
          <br />Category
          <font-awesome-icon v-if="!isCategoriesValid" class="n-error" icon="exclamation-circle" />
        </template>
        <template>
          <ProductCategories :categories="availableCategories"></ProductCategories>
        </template>
      </b-tab>
      <b-tab>
        <template v-slot:title>
          Assign Role(s)
          <br />&amp; Order Limits
          <font-awesome-icon v-if="!isRolesValid" class="n-error" icon="exclamation-circle" />
        </template>
        <template>
          <ProductRoles :limitTypes="availableLimitTypes" :product="product" :roles="availableRoles"
            :rolesConfig="rolesConfig" :fieldDisabled="isDisabled"></ProductRoles>
        </template>
      </b-tab>
      <b-tab>
        <template v-slot:title> Search<br /><br /> </template>
        <template>
          <ProductKeywords />
        </template>
      </b-tab>
      <b-tab>
        <template v-slot:title> Activity<br /><br /> </template>
        <template>
          <Activity :productId="product.id"/></template>
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";

import {
  EDIT_PRODUCT_STEPPER_GETTER,
  PRODUCT_FOR_EDIT_GETTER,
} from "@/nucleus-modules/dd-nucleus-admin/store/getters.type.js";
import { SET_STEPPER_CURRENT_STEP } from "@/nucleus-modules/dd-nucleus-admin/store/mutations.type.js";
import { SITE_SETUP_GETTER } from "@/nucleus-modules/dd-nucleus-storefront/store/getters.type.js";
import GeneralInformation from "@/components/admin/product/edit-product/GeneralInformation.vue";
import AdditionalInformation from "@/components/admin/product/edit-product/AdditionalInformation.vue";
import InventoryManagement from "@/components/admin/product/edit-product/InventoryManagement.vue";
import ProductAvailability from "@/components/admin/product/edit-product/ProductAvailability.vue";
import ProductCategories from "@/components/admin/product/edit-product/ProductCategories.vue";
import ProductKeywords from "@/components/admin/product/edit-product/ProductKeywords.vue";
import Activity from "@/components/admin/product/edit-product/Activity.vue";
import ProductMedia from "@/components/admin/product/edit-product/ProductMedia.vue";
import ProductRoles from "@/components/admin/product/edit-product/ProductRoles.vue";
import { AdminEvents } from "@/nucleus-modules/dd-nucleus-admin/constants.js";

export default {
  name: "EditTabs",
  components: {
    GeneralInformation,
    AdditionalInformation,
    InventoryManagement,
    ProductAvailability,
    ProductCategories,
    ProductKeywords,
    ProductMedia,
    ProductRoles,
    Activity,
  },
  props: {
    rolesConfig: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      tabIndex: 0,
      isDisabled:false,
    };
  },
  computed: {
    ...mapGetters({
      editProductStepper: EDIT_PRODUCT_STEPPER_GETTER,
      product: PRODUCT_FOR_EDIT_GETTER,
      siteSetup: SITE_SETUP_GETTER,
    }),
    availableCategories() {
      if (this.siteSetup && this.siteSetup.site && this.siteSetup.site.menus) {
        var categories = this.siteSetup.site.menus.find((c) => c.id == "main");
        if (categories && categories.children && categories.children.length) {
          const arrangeSubCat = categories.children.filter((c) => c.id != "all-categories");
          this.sortCategories(arrangeSubCat);
          return arrangeSubCat;
        } else {
          return [];
        }
      } else return [];
    },
    availableLimitTypes() {
      return this.siteSetup.limitTypes;
    },
    availableRoles() {
      if (this.siteSetup && this.siteSetup.roles) return this.siteSetup.roles;
      else return [];
    },
    defaultOutOfStockMessageText() {
      if (this.siteSetup && this.siteSetup.constants)
        return this.siteSetup.constants.defaultOutOfStockMessageText;
      else return "";
    },
    isCategoriesValid() {
      return this.product.tabValidation.isCategoriesValid || this.isDisabled;
    },
    isGeneralInfoValid() {
      return this.product.tabValidation.isGeneralInfoValid;
    },
    isRolesValid() {
      return this.product.tabValidation.isRolesValid;
    },
    imageConfig() {
      return this.siteSetup.imageConfig;
    },
  },
  methods: {
    ...mapMutations({
      updateStep: SET_STEPPER_CURRENT_STEP,
    }),
    changed(value){
      return this.isDisabled = value
    },
    initListeners() {
      const vm = this;

      this.$eventBus.$on(AdminEvents.STEPPER_STEP_NEXT, () => {
        vm.updateStep(++vm.editProductStepper.currentStep);
        vm.tabIndex = vm.editProductStepper.currentStep;
      });

      this.$eventBus.$on(AdminEvents.STEPPER_STEP_PREVIOUS, () => {
        vm.updateStep(--vm.editProductStepper.currentStep);
        vm.tabIndex = vm.editProductStepper.currentStep;
      });
    },
    onTabActivated(newTabIndex) {
      this.updateStep(newTabIndex);
      this.tabIndex = this.editProductStepper.currentStep;
    },
    removeListeners() {
      this.$eventBus.$off(AdminEvents.STEPPER_STEP_NEXT);
      this.$eventBus.$off(AdminEvents.STEPPER_STEP_PREVIOUS);
    },
    sortCategories(arrangeSubCat) {

      arrangeSubCat.forEach((fc) => {
        if (fc.children && fc.children.length > 0) {
          fc.children = fc.children.sort((a, b) => (a.text > b.text) ? 1 : -1);
          this.sortCategories(fc.children);
        }
      });
    },
  },
  beforeDestroy() {
    this.removeListeners();
  },
  mounted() {
    this.initListeners(); 
  },
};
</script>

<style lang="scss">
@media screen and (max-width: 1240px) {
 div.tab-parent-container div.tabs div ul.nav-tabs {
    flex-wrap: nowrap !important;
    white-space: nowrap !important;
    overflow: overlay !important;
    overflow-y: hidden !important;
    height: 100px !important;
  }

  /* width */
  .nav-tabs::-webkit-scrollbar {
    height: .75rem;
  }

  /* Track */
  .nav-tabs::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px rgb(233, 231, 231);
    border-radius: 10px;

  }

  /* Handle */
  .nav-tabs::-webkit-scrollbar-thumb {
    border-radius: 8px;
    background: #d4d3d3;
  }
}
</style>
