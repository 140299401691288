<template>
  <div>
    <h4 class="display">Add Item</h4>

    <a @click="cancel"> {{ backButtonConfig.text }}</a>

    <div class="d-flex">
      <div class="flex-grow-1">
        <h5 class="n-form-section-header n-success">{{ name }}</h5>
      </div>
      <div>
        <h3 class="n-product-status text-right">{{ status }}</h3>
      </div>
    </div>
    <div class="d-flex">
      <div class="flex-grow-1">
        <h6 class="n-form-section-subheader">{{ itemNumber }}</h6>
      </div>
      <div>
        <div class="actions">
          <button class="btn btn-outline-primary btn-sm mr-3" @click="cancel()">
            Cancel
          </button>
          <button class="btn btn-primary btn-sm" @click="saveProduct()">Save</button>
          <span class="ml-3 mr-3">|</span>
          <button
            v-if="status === statuses.ACTIVE"
            class="btn btn-success btn-sm"
            @click="removeFromStore()"
          >
            Remove from Store
          </button>
          <button
            v-if="status !== statuses.ACTIVE"
            class="btn btn-success btn-sm"
            @click="addToStore()"
            :disabled="disableAddToStore"
          >
            Add to Store
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import { PRODUCT_FOR_EDIT_GETTER } from "@/nucleus-modules/dd-nucleus-admin/store/getters.type.js";
import { SET_PRODUCT_PROPERTY } from "@/nucleus-modules/dd-nucleus-admin/store/mutations.type.js";
import {
  AdminEvents,
  ProductStatus,
} from "@/nucleus-modules/dd-nucleus-admin/constants.js";
import ConfirmationBoxService from "@/nucleus-modules/dd-nucleus-ui/services/confirmation-box.service.js";

export default {
  name: "EditProductHeader",
  props: {
    backButtonConfig: {
      type: Object,
      required: true,
    },
    disableAddToStore: {
      type: Boolean,
      required: false,
    },
  },

  computed: {
    ...mapGetters({
      product: PRODUCT_FOR_EDIT_GETTER,
    }),
  },
  data() {
    return {
      name: "",
      itemNumber: "",
      status: "",
      statuses: ProductStatus,
    };
  },
  methods: {
    ...mapMutations({
      setProductProperty: SET_PRODUCT_PROPERTY,
    }),
    addToStore() {
      this.setProductProperty({ name: "status", value: ProductStatus.ACTIVE });
      this.saveProduct();
    },
    isDirty() {
      let initialValues = this.product;
      let currentValues = this.productCompare;
      initialValues.availability = this.productCompare.availability;
      initialValues.roles = this.productCompare.roles;
      initialValues.attributes.FeaturedProductForUserRoles =
        currentValues.attributes.FeaturedProductForUserRoles;
      initialValues.tags = currentValues.tags;
      if (JSON.stringify(initialValues) !== JSON.stringify(currentValues)) {
        return true;
      }
      return false;
    },
    async cancel() {
      if (this.isDirty()) {
        const cancelConfirmation = new ConfirmationBoxService({});
        cancelConfirmation.options.size = "md";
        if (this.isValid) {
          cancelConfirmation.options.okTitle = "Save";

          const confirmed = await cancelConfirmation.showConfirm(
            `Would you like to save your changes to product ${this.product.name} before leaving?`
          );

          if (confirmed) {
            await this.save();
          }
          this.$eventBus.$emit(AdminEvents.CANCEL_PRODUCT_EDIT);
        } else {
          cancelConfirmation.options.cancelTitle = "Cancel";
          cancelConfirmation.options.okTitle = "Close";
          var productNameValue = "";
          if (this.product.name != null) {
            productNameValue = this.product.name;
          }
          const confirmed = await cancelConfirmation.showConfirm(
            <span v-html="rawHtml">
              {" "}
              The product <strong> {productNameValue} </strong> has been
              modified. Choose <strong> Close </strong>to continue editing this
              product or <strong> Cancel </strong>
              to exit without saving changes.{" "}
            </span>
          );

          if (confirmed) {
            // close the confirmation box and allow the user to continue editing
          } else {
            this.$eventBus.$emit(AdminEvents.CANCEL_PRODUCT_EDIT);
          }
        }
      } else {
        this.$eventBus.$emit(AdminEvents.CANCEL_PRODUCT_EDIT);
      }
    },
    removeFromStore() {
      this.setProductProperty({
        name: "status",
        value: ProductStatus.INACTIVE,
      });
      this.saveProduct();
    },
    saveProduct() {
      this.$eventBus.$emit(AdminEvents.SAVE_PRODUCT, {
        productId: this.product.id,
      });
    },
    toggleAddToStore() {
      this.$eventBus.$emit(AdminEvents.SAVE_PRODUCT, {
        productId: this.product.id,
      });
    },
  },
  mounted() {
    this.name = Object.freeze(this.product.name);
    this.itemNumber = Object.freeze(this.product.itemNumber);
    this.status = Object.freeze(this.product.status);
    this.productCompare = JSON.parse(JSON.stringify(this.product));
  },
};
</script>

<style lang="scss" scoped>
.display{
      display: inline-block;
      width: 5em;
}
</style>
