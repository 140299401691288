import Vue from "vue"
import VueRouter from "vue-router"
import Home from "@/pages/Home.vue";
import SignIn from "@/pages/SignIn.vue";
import ProductDetail from "@/pages/ProductDetail.vue";
import ProductSearchResults from "@/pages/ProductSearchResults.vue";
import ForgotPasswordPage from "@/pages/ForgotPasswordPage.vue";
import ShoppingCart from "@/pages/ShoppingCart.vue";
import FAQ from "@/pages/Faq.vue";
import SiteMap from "@/pages/SiteMap.vue";
import Accessibility from "@/pages/Accessibility.vue";
import MyPassword from "@/pages/MyPassword.vue";
import MyProfile from "@/pages/MyProfile.vue";
import AllReports from '@/pages/admin/reports/All-Reports'
import AddReport from '@/pages/admin/reports/Add-Report'
import ReportDetails from '@/pages/admin/reports/Report-Details'
import TestShipMethod from "@/pages/test/TestShipMethod.vue";
import EmulationEnded from "@/pages/EmulationEnded.vue";
import ContactUs from "@/pages/ContactUs.vue";

// order related pages
import Checkout from "@/pages/Checkout.vue";
import OrderHistory from "@/pages/OrderHistory.vue";
import OrderDetail from "@/pages/OrderDetail.vue";
import ReviewOrder from "@/pages/ReviewOrder.vue";
import OrderConfirmation from "@/pages/OrderConfirmation.vue";
import TestInventory from "@/pages/test/TestInventory.vue";

//admin
import AddEditUser from "@/pages/admin/user/AddEditUser.vue";
// import AdminLanding from "@/pages/admin/Landing.vue";
import AllOrders from "@/pages/admin/orders/AllOrders.vue";
import AllProducts from "@/pages/admin/product/AllProducts.vue";
import AllUsers from "@/pages/admin/user/AllUsers.vue";
import BulkOrders from "@/pages/admin/bulk-order/BulkOrders.vue";
import OrderApprovals from "@/pages/admin/bulk-order/OrderApprovals.vue";
import EditProduct from "@/pages/admin/product/EditProduct.vue";
import OrderDetails from "@/pages/admin/bulk-order/OrderDetails.vue";
import ContentManagement from "@/pages/admin/content-management/ContentManagement.vue"

//my account related pages 
import CRMAddresses from "@/pages/CRMAddresses.vue"
import SignInMethod from "@/pages/SignInMethod.vue";
import SSOLogout from "@/pages/SSOLogout.vue";
import SSOError from "@/pages/SSOError.vue";
import SiteMaintenance from "@/pages/SiteMaintenance.vue";

import { LoginCallback } from "@okta/okta-vue";
import AddEditAnnouncement from "@/pages/admin/content-management/AddEditAnnouncement.vue";
// Hiding these two components/routes for now, should be good
// to delete them by the end of the week (5/20/22)
// import ComingSoon from "@/pages/ComingSoon.vue";
// import DataLoad from "@/pages/DataLoad.vue";


Vue.use(VueRouter)

export const LOGIN_PATH = "/sign-in-method";
export const DIRECT_LOGIN_PATH = "/sign-in";
export const SSO_LOGOUT_PATH = "/sso-logout";
export const SSO_ERROR_PATH = "/sso-error";
export const ADMIN_ENTRY_PATH = "/admin.html";
export const INDEX_ENTRY_PATH = "/index.html";
export const ADMIN_LANDING_PATH = "/admin";



const routes = [
    // {
    //     path: "/",
    //     name: "Coming Soon",
    //     component: ComingSoon
    // },
    // {
    //     path: "/data-load",
    //     name: "Data Load",
    //     component: DataLoad
    // },
    {
        path: "/site-maintenance",
        name: "Site Maintenance",
        component: SiteMaintenance
    },
    {
        path: "/login/callback",
        component: LoginCallback
    },
    {
        path: "/",
        name: "Home",
        component: Home,
    },
    {
        path: "/password",
        name: "My Password",
        component: MyPassword
    },
    {
        path: "/profile",
        name: "My Profile",
        component: MyProfile,
        meta: {
            title: "Profile"
        }
    },
    {
        path: "/contact-us",
        name: "Contact Us",
        component: ContactUs,
        meta: {
            title: "Contact Us"
        }
    },
    {
        path: DIRECT_LOGIN_PATH,
        name: "Sign In",
        component: SignIn
    },
    {
        path: LOGIN_PATH,
        name: "Sign In Method",
        component: SignInMethod
    },
    {
        path: SSO_LOGOUT_PATH,
        name: "Sign Out",
        component: SSOLogout
    },
    {
        path: SSO_ERROR_PATH,
        name: "SSO Error",
        component: SSOError
    },

    {
        path: "/forgot-password",
        name: "Forgot Password",
        component: ForgotPasswordPage
    },
    {
        path: "/shopping-cart",
        name: "Shopping Cart",
        component: ShoppingCart
    },
    {
        path: "/checkout",
        name: "Checkout",
        component: Checkout
    },
    {
        path: "/orders",
        name: "OrderHistory",
        component: OrderHistory,
        meta: {
            title: "Order History"
        }
    },
    {
        path: "/order-detail/:orderId",
        name: "OrderDetail",
        component: OrderDetail,
        meta: {
            title: "Order Detail"
        }
    },
    {
        path: "/product-detail/:productId",
        name: "product-detail",
        component: ProductDetail,
        meta: {
            title: "Product Detail"
        }
    },
    {
        path: "/product-results",
        name: "ProductSearchResults",
        component: ProductSearchResults,
        meta: {
            title: "Products",
            disableScroll: true
        }
    },
    {
        path: "/faq",
        name: "faq",
        component: FAQ,
        meta: {
            title: "FAQ"
        }
    },
    {
        path: "/site-map",
        name: "SiteMap",
        component: SiteMap,
        meta: {
            title: "Site Map"
        }
    },
    {
        path: "/emulation-ended",
        name: "EmulationEnded",
        component: EmulationEnded,
        meta: {
            title: "Emulation Ended"
        }
    },
    {
        path: "/review-order",
        name: "ReviewOrder",
        component: ReviewOrder
    },
    {
        path: "/order-confirmed",
        name: "OrderConfirmation",
        component: OrderConfirmation
    },
    {
        path: "/accessibility",
        name: "Accessibility",
        component: Accessibility
    },
    {
        path: "/crm-addresses",
        name: "CRM Addresses",
        component: CRMAddresses,
        meta: {
            title: 'CRM Addresses'
        }
    },
    {
        path: "/test-shipmethod",
        name: "TestShipMethod",
        component: TestShipMethod
    },
    {
        path: "/test-inventory-real-time",
        name: "TestInventory",
        component: TestInventory
    },
    //Admin Area
    {
        path: "/admin",
        name: "AdminLanding",
        component: AllProducts,
        meta: {
            title: "All Products"
        }
    },
    {
        path: "/admin/all-products",
        name: "AllProducts",
        component: AllProducts,
        meta: {
            title: "All Products"
        }
    },
    {
        path: "/admin/edit-product",
        name: "EditProduct",
        component: EditProduct,
        meta: {
            title: "Edit Product"
        }
    },
    {
        path: "/admin/all-users",
        name: "AllUsers",
        component: AllUsers,
        meta: {
            title: "All Users"
        }
    },
    {
        path: "/admin/add-user",
        name: "AddUser",
        component: AddEditUser,
        props: () => ({
            id: null,
            editable: true
        }),
        meta: {
            title: "Add User"
        }
    },
    {
        path: "/admin/edit-user/:id",
        name: "EditUser",
        component: AddEditUser,
        props: route => ({
            id: route.params.id,
            editable: true,
            refresh: route.params.refresh
        }),
        meta: {
            title: "Edit User"
        }
    },
    {
        path: "/admin/order/:id",
        name: "ViewOrder",
        component: OrderDetails,
        props: route => ({
            id: route.params.id,
            editable: true,
            refresh: route.params.refresh
        }),
        meta: {
            title: "View Order"
        }
    },
    {
        path: "/admin/all-orders",
        name: "AllOrders",
        component: AllOrders,
        props: {
            filterByStatusProp: ""
        },
        meta: {
            title: "All Orders"
        }
    },
    {
        path: "/admin/completed-orders",
        name: "CompletedOrders",
        component: AllOrders,
        props: {
            filterByStatusProp: "completed"
        },
        meta: {
            title: "Completed Orders"
        }
    },
    {
        path: "/admin/pending-orders",
        name: "PendingOrders",
        component: AllOrders,
        props: {
            filterByStatusProp: "pending"
        },
        meta: {
            title: "Pending Orders"
        }
    },
    {
        path: "/admin/pending-approval-orders",
        name: "PendingApprovalOrders",
        component: AllOrders,
        props: {
            filterByStatusProp: "pending-approval"
        },
        meta: {
            title: "Pending Approval"
        }
    },
    {
        path: "/admin/cancelled-orders",
        name: "CancelledOrders",
        component: AllOrders,
        props: {
            filterByStatusProp: "canceled"
        }
    },
    {
        path: "/admin/user/:id",
        name: "ViewUser",
        component: AddEditUser,
        props: route => ({
            id: route.params.id,
            editable: false
        }),
        meta: {
            title: "View User"
        }
    },
    {
        path: "/admin/bulk-orders",
        name: "BulkOrders",
        component: BulkOrders,
        meta: {
            title: "Bulk Orders"
        }
    },
    {
        path: "/admin/order-approvals",
        name: "OrderApprovals",
        component: OrderApprovals,
        meta: {
            title: "Order Approvals"
        }
    },
    {
        path: "/admin/order/:id",
        name: "OrderDetails",
        component: OrderDetails,
        meta: {
            title: "Order Details"
        }
    },
    {
        path: '/admin/all-reports',
        name: 'AllReports',
        component: AllReports,
        meta: {
            title: 'All Reports'
        },
    },
    {

        path: '/admin/add-edit-report',
        name: 'AddEditReport',
        component: AddReport,
        meta: {
            title: 'Add or Edit a Report'
        }
    },
    {
        path: '/admin/report-detail',
        name: 'Report Details',
        component: ReportDetails,
        meta: {
            title: 'Report Details'
        }
    },
    {
        path: '/admin/content-management',
        name: 'Content Management',
        component: ContentManagement,
        meta: {
            title: 'Content Management'
        }
    },
    {
        path: '/admin/add-edit-announcement',
        name: 'AddEditAnnouncement',
        component: AddEditAnnouncement,
        meta: {
            title: 'Add or Edit an Announcement'
        }
    }
]

const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes,
    scrollBehavior(to, from, savedPosition) {
        //Stop scrolling to the top of the page for the product results page.
        // the routes with disableScroll will not scroll back to top of the page.
        if (to.matched.some(m => m.meta.disableScroll)) return;
        if (savedPosition) {
            return savedPosition
        } else {
            return { x: 0, y: 0 }
        }
    }
})
export default router
