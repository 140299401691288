<template>
  <div>
    <PageSubHeader title="Primary Information" :hasThematicBreak="true" />
    <div class="row">
      <div class="col-xs-12 col-sm-5">
        <dl>
          <dt>First Name:</dt>
          <dd>{{ user.firstName }}</dd>

          <dt>Last Name:</dt>
          <dd>{{ user.lastName }}</dd>

          <dt>
            Buiness Name: <span class="n-form-label-notes">(optional)</span>
          </dt>
          <dd>
            <span v-if="user.companyName">{{ user.companyName }}</span>
            <span v-else>No Business Name</span>
          </dd>

          <dt>Job Title: <span class="n-form-label-notes">(optional)</span></dt>
          <dd>
            <span v-if="user.title">{{ user.title }}</span>
            <span v-else>No Job Title</span>
          </dd>

          <dt>Date Created:</dt>
          <dd>
            <span v-if="user.created">{{
              user.created | moment("MM/DD/YYYY hh:mm a")
            }}</span>
            <span v-else>No Created Date</span>
          </dd>
        </dl>
      </div>
      <div class="col-xs-12 offset-sm-2 col-sm-5">
          <dl>
              <dt>User ID:</dt>
              <dd>{{ user.userName }}</dd>
          </dl>
          <dl>
              <dt>Is SSO:</dt>
              <dd>
                  <span v-if="user.attributes.IsSso">
                      {{ user.attributes.IsSso }}
                  </span>        
                  <span v-else>false</span>
              </dd>
          </dl>
      </div>
    </div>
  </div>
</template>

<script>
import PageSubHeader from "@/nucleus-modules/dd-nucleus-admin/components/PageSubHeader.vue";

export default {
  name: "UserPrimaryInfoViewOnly",
  props: {
    user: { type: Object },
  },
  components: {
    PageSubHeader,
  },
};
</script>

<style>
dt {
  margin-top: 1.5rem !important;
}
</style>
