<template>
  <AdminTemplate>
    <p v-if="isLoading">Loading...</p>
    <div v-else class="edit-product-content">
      <EditProductHeader
        :key="editProductHeaderKey"
        :backButtonConfig="backButtonConfig"
        :disableAddToStore="disableAddToStore"
        class="mb-3"
      ></EditProductHeader>
      <ProductInformationalMessage
        :firstUseDate="product.startOrderDate"
        :quarantineDate="product.endOrderDate"
        :daysToQuarantineDate="daysToQuaratineDate"
        :isActive="IsAvailableOnStore"
        :isCategoriesValid="isCategoriesValid"
        :isRolesValid="isRolesValid"
      ></ProductInformationalMessage>

      <EditTabs :rolesConfig="rolesConfig"></EditTabs>

      <div class="row">
        <div class="col mt-5">
          <Stepper :stepperConfig="editProductStepperConfig"></Stepper>
        </div>
      </div>
      <div class="invisible">
        <!-- TODO: remove these, needed for now to trigger tab validation icons -->
        {{ categories }} {{ productName }} {{ itemNumber }}
      </div>
    </div>
  </AdminTemplate>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";

import {
  GET_PRODUCT_FOR_EDIT,
  GET_PRODUCT_MEDIA,
  REMOVE_PRODUCT_MEDIA,
  UPDATE_PRODUCT_MEDIA_SORT,
} from "@/nucleus-modules/dd-nucleus-admin/store/actions.type.js";
       
import {
  EDIT_PRODUCT_STEPPER_GETTER,
  IS_PRODUCT_EDIT_DIRTY_GETTER,
  PRODUCT_FOR_EDIT_GETTER,
  SAVE_PRODUCT_ERROR_GETTER,
} from "@/nucleus-modules/dd-nucleus-admin/store/getters.type.js";
import {
  SET_EDIT_PRODUCT_STEPPER,
  SET_IS_PRODUCT_EDIT_DIRTY,
  SET_PRODUCT_PROPERTY,
} from "@/nucleus-modules/dd-nucleus-admin/store/mutations.type.js";
import Stepper from "@/nucleus-modules/dd-nucleus-admin/components/Stepper.vue";
import ToastService from "@/nucleus-modules/dd-nucleus-ui/services/toast.service.js";
import { CLEAR_ERRORS } from "@/nucleus-modules/dd-nucleus-ui/store/actions.type.js";
import { REGENERON_SAVE_PRODUCT } from "@/store/admin/actions.type.js";
import { GET_NEW_PRODUCT } from "@/store/storefront/actions.type.js";
import EditProductHeader from "@/components/admin/product/edit-product/EditProductHeader.vue";
import EditTabs from "@/components/admin/product/edit-product/EditTabs.vue";

import {
  AdminEvents,
  ProductStatus,
} from "@/nucleus-modules/dd-nucleus-admin/constants.js";
import ProductInformationalMessage from "@/components/admin/product/edit-product/ProductInformationalMessage.vue";
import { errorMixin } from "@/mixins/errorMixin.js";
import AdminTemplate from "@/pages/templates/AdminTemplate.vue";
import {DefaultAddOnItemQuantity} from "@/constants.js";

export default {
  name: "EditProduct",
  mixins: [errorMixin],
  components: {
    Stepper,
    EditProductHeader,
    ProductInformationalMessage,
    EditTabs,
    AdminTemplate,
  },
  data() {
    return {
      backButtonConfig: {
        path: "/admin/all-products",
        text: "Back to All Items",
      },
      editProductHeaderKey: 0,
      isLoading: true,
      rolesConfig: {
        options: {
          hasGuestUsers: false,
        },
      },
    };
  },
  computed: {
    ...mapGetters({
      editProductStepperConfig: EDIT_PRODUCT_STEPPER_GETTER,
      isDirty: IS_PRODUCT_EDIT_DIRTY_GETTER,
      product: PRODUCT_FOR_EDIT_GETTER,
      saveProductError: SAVE_PRODUCT_ERROR_GETTER,
    }),
    categories() {
      this.setTabValidations();
      return this.product.categories;
    },
    productName() {
      this.setTabValidations();
      return this.product.name;
    },
    itemNumber() {
      this.setTabValidations();
      return this.product.itemNumber;
    },
    daysToQuaratineDate() {
      if (
        this.product &&
        this.product.attributes &&
        this.product.attributes.daysToQuarantineDate
      ) {
        return Number(this.product.attributes.daysToQuarantineDate);
      } else return 0;
    },
    IsAvailableOnStore() {
      if (this.product && this.product.status == ProductStatus.ACTIVE) {
        return true;
      } else return false;
    },
    disableAddToStore() {
      return !this.isCategoriesValid || !this.isRolesValid;
    },
    isCategoriesValid() {
      if ((this.product.categories && this.product.categories.length > 0) || (this.product.attributes.ExtendedProductProperties.isAddOn)) {
        return true;
      } else return false;
    },
    isRolesValid() {
      if (this.product.roles && this.product.roles.length > 0) {
        return true;
      } else return false;
    },
  },
  methods: {
    ...mapActions({
      getNewProduct: GET_NEW_PRODUCT,
      getProductForEdit: GET_PRODUCT_FOR_EDIT,
      getProductMedia: GET_PRODUCT_MEDIA,
      removeProductMedia: REMOVE_PRODUCT_MEDIA,
      saveProduct: REGENERON_SAVE_PRODUCT,
      updateProductMediaSort: UPDATE_PRODUCT_MEDIA_SORT,
      clearErrors: CLEAR_ERRORS,
    }),
    ...mapMutations({
      setEditProductStepper: SET_EDIT_PRODUCT_STEPPER,
      setIsProductEditDirty: SET_IS_PRODUCT_EDIT_DIRTY,
      setProductProperty: SET_PRODUCT_PROPERTY,
    }),
    initListeners() {
      const vm = this;

      this.$eventBus.$on(AdminEvents.CANCEL_PRODUCT_EDIT, () => {
        vm.$router.push(vm.backButtonConfig.path);
      });

      this.$eventBus.$on(AdminEvents.PRODUCT_IMAGE_REMOVED, async (payload) => {
        await vm.removeProductMedia({
          productId: vm.product.id,
          mediaId: payload.id,
        });

        await vm.getProductMedia({ productId: vm.product.id });
      });

      this.$eventBus.$on(AdminEvents.PRODUCT_IMAGE_UPLOADED, async () => {
        await vm.getProductMedia({ productId: vm.product.id });
      });

      this.$eventBus.$on(AdminEvents.PRODUCT_IMAGES_RERANKED, async () => {
        await vm.updateProductMediaSort({
          productId: vm.product.id,
          mediaItemIds: vm.product.media.map((m) => m.id),
        });
      });

      this.$eventBus.$on(AdminEvents.SAVE_PRODUCT, async (payload) => {
        let product = vm.product;

        product.productId = payload.productId;

        if (!vm.$nucleus.isEmpty(vm.product.endOrderDate))
        {
          product.endDisplayDate = this.$moment(vm.product.endOrderDate)
            .subtract(
              vm.product.attributes.ExtendedProductProperties
                .daysToQuarantineDate,
              "days"
            )
            .format("YYYY-MM-DD");
        }
        else{
          product.endDisplayDate = null;
        }

          product.startDisplayDate = vm.product.startOrderDate;

        if (
          vm.product &&
          vm.product.attributes &&
          vm.product.attributes.Inventory &&
          vm.product.attributes.Inventory.unitOfMeasure
        ) {
          product.unitOfMeasure = vm.product.attributes.Inventory.unitOfMeasure;
          product.quantityPerUnit =
            vm.product.attributes.Inventory.quantityPerUnit;
        }

        if( vm.product.attributes &&
            vm.product.attributes.ExtendedProductProperties && 
            vm.product.attributes.ExtendedProductProperties.isAddOn){
            product.attributes.Inventory.quantityAvailable = DefaultAddOnItemQuantity;
        }
        
        if( vm.product.attributes &&
            vm.product.attributes.ExtendedProductProperties && 
            vm.product.attributes.ExtendedProductProperties.daysToQuarantineDate == null){
            product.attributes.daysToQuarantineDate = 0;
            product.attributes.ExtendedProductProperties.daysToQuarantineDate = 0;
        }

        await vm.saveProduct(product);

        if (!vm.$nucleus.isEmpty(vm.saveProductError)) {
          let saveErrors = Object.keys(vm.saveProductError);
          let messages = "";

          saveErrors.forEach((i) => {
            messages = messages + vm.saveProductError[i].debugMessage;
          });

          ToastService.showErrorToast(messages);
          this.clearErrors();
        } else {
          // Update the key to force a reactive cycle
          vm.editProductHeaderKey += 1;
          vm.setTabValidations();
          ToastService.showSuccessToast("This item has been saved.");
        }
      });
    },
    removeListeners() {
      this.$eventBus.$off(AdminEvents.CANCEL_PRODUCT_EDIT);
      this.$eventBus.$off(AdminEvents.PRODUCT_IMAGE_REMOVED);
      this.$eventBus.$off(AdminEvents.PRODUCT_IMAGE_UPLOADED);
      this.$eventBus.$off(AdminEvents.PRODUCT_IMAGES_RERANKED);
      this.$eventBus.$off(AdminEvents.SAVE_PRODUCT);
    },
    setTabValidations() {
      const validations = {
        isCategoriesValid: false,
        isGeneralInfoValid: false,
        isRolesValid: false,
      };
      let addOnQuantity = this.product?.attributes?.ExtendedProductProperties?.addOnQuantity;
      let isAddOn = this.product?.attributes?.ExtendedProductProperties?.isAddOn;
      let isValidAddOnQuantity = !(isAddOn && (Number(addOnQuantity) != "NaN"  && Number(addOnQuantity) <= 0));

      if (this.product.categories && this.product.categories.length > 0) {
        validations.isCategoriesValid = true;
      }

      if (this.product.roles && this.product.roles.length > 0) {
        validations.isRolesValid = true;
      }

      if (
        this.product.name &&
        this.product.name !== "" &&
        this.product.itemNumber &&
        this.product.itemNumber !== "" &&
        isValidAddOnQuantity
      ) {
        validations.isGeneralInfoValid = true;
      }
      if (
        this.$nucleus.isEmpty(this.product.tabValidation) ||
        this.product.tabValidation.isCategoriesValid !=
          validations.isCategoriesValid ||
        this.product.tabValidation.isGeneralInfoValid !=
          validations.isGeneralInfoValid ||
        this.product.tabValidation.isRolesValid != validations.isRolesValid
      ) {
        this.setProductProperty({ name: "tabValidation", value: validations });
      }
    },
  },
  beforeDestroy() {
    this.removeListeners();
  },
  async mounted() {

    this.initListeners();
    if (this.$route.query && this.$route.query.productId) {
      await this.getProductForEdit({ productId: this.$route.query.productId });   
    } else {
      await this.getNewProduct();
    }

    this.setEditProductStepper({
      currentStep: 0,
      totalSteps: 7,
      stepNextEventName: AdminEvents.STEPPER_STEP_NEXT,
      stepPreviousEventName: AdminEvents.STEPPER_STEP_PREVIOUS,
    });

    this.setIsProductEditDirty(false);
    this.setTabValidations();
    await this.showNetworkError();
    this.isLoading = false;
  },
};
</script>

<style lang="scss" scoped>
.edit-product-content {
  padding-bottom: 30px;
}

::v-deep {
  .b-calendar-grid-body {
    min-height: 14.25rem !important;
  }
}
</style>
