<template>
  <div>
    <h4 v-if="rolesConfig.options.hasGuestUsers">Associate Item to Role(s)</h4>
    <div class="row">
      <div class="col">
        <div v-if="rolesConfig.options.hasGuestUsers">
          <b-form-group>
            <b-form-radio
              v-model="selected"
              name="roles"
              :value="productAvailability.ALL"
              >Available to All Roles (Guests and/or Customers)</b-form-radio
            >
            <b-form-radio
              v-model="selected"
              name="roles"
              :value="productAvailability.LOGGED_IN"
              >Available to signed in users</b-form-radio
            >
            <b-form-radio
              v-model="selected"
              name="roles"
              :value="productAvailability.ROLE_BASED"
              >Assign specific role(s) and order limits</b-form-radio
            >
          </b-form-group>
        </div>
        <div v-if="selected === productAvailability.ROLE_BASED">
          <div class="row">
            <div class="col">
              <h4>Assign Role(s) &amp; Order Limits</h4>
            </div>
          </div>
          <div class="row">
            <div class="col mb-2">
              <a @click.prevent="selectAllRoles()">Select All Roles</a>
            </div>
          </div>
          <div
            v-for="role in roleStructure.items"
            :key="role.id"
            class="row n-role-row"
          >
            <div class="col pb-2 pt15" :class="{ selected: role.selected }">
              
              <div class="row">
                <div class="col-4">
                  <label>
                    <input
                      type="checkbox"
                      v-model="role.selected"
                      name="roles"
                      @input="toggle(role)"
                    />
                    <span class="n-role-name ml-3">{{ role.name }}</span>
                  </label>
                </div>
                <div class="col-8">
                  <template v-if="role.selected">
                    <label>
                      <input
                        type="checkbox"
                        name="featuredItems"
                        v-model="role.isFeaturedItem"
                        @click="toggleFeatureItem(role)"
                        :disabled="fieldDisabled"
                      />
                      <span class="n-role-name ml-3">Featured Items</span>
                    </label>
                  </template>
                </div>
              </div>

              <div v-if="role.selected">
                <div
                  v-for="limit in role.limits"
                  :key="limit.code"
                >
                  <div class="row">
                    <div class="col">
                        <strong>
                            {{ getLimitText(limit.code) }} Order Quantity
                            <font-awesome-icon class="n-question-icon" icon="question-circle" />
                        </strong>
                    </div>
                  </div>
                  <div class="row form-group">
                    <div class="col-4">
                      <input
                        type="number"
                        min="1"
                        class="form-control"
                        placeholder="Unlimited"
                        v-model.number="limit.value"
                        @input="updateLimitValue(role, limit)"
                        :disabled="fieldDisabled"
                      />
                    </div>
                    <div class="col-8">
                      <label>
                        <a
                          v-if="limit.value && limit.value > 0"
                          @click.prevent="applyLimitToAllSelectedRoles(limit)"
                          >Apply Limit to All Selected Roles</a
                        >
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import { mapGetters, mapMutations } from "vuex";    
import {
  SET_IS_PRODUCT_EDIT_DIRTY,
  SET_PRODUCT_PROPERTY,
} from "@/nucleus-modules/dd-nucleus-admin/store/mutations.type.js";
import { PRODUCT_PROPERTY_GETTER } from "@/nucleus-modules/dd-nucleus-admin/store/getters.type.js";
import { ProductAvailability } from "@/nucleus-modules/dd-nucleus-admin/constants.js";
import { TagNames } from "@/nucleus-modules/dd-nucleus-storefront/constants.js";

const ATTRIBUTE_NAME = "FeaturedProductForUserRoles";

export default {
  name: "ProductRoles",
  props: {
    limitTypes: {
      type: Array,
    },
    product: {
      type: Object,
      required: true,
    },
    roles: {
      type: Array,
      required: true,
    },
    rolesConfig: {
      type: Object,
      required: true,
    },
    fieldDisabled: {
      type: Boolean,
      required: false
    }
  },
  data() {
    return {
      firstWatch: true,
      productAvailability: ProductAvailability,
      selected: null,
      roleStructure: {},
    };
  },
  computed: {
    ...mapGetters({               
      getProductProperty: PRODUCT_PROPERTY_GETTER,
    }),
    featuredProductRoles() {
          let featuredUserRoleIds = this.getProductProperty("featuredProductUserRoleIds", ATTRIBUTE_NAME);
          if (!this.$nucleus.isEmpty(featuredUserRoleIds)) {
            return featuredUserRoleIds;
          }
        return [];
    },
  },
  methods: {
    ...mapMutations({
      setProductProperty: SET_PRODUCT_PROPERTY,
      setIsProductEditDirty: SET_IS_PRODUCT_EDIT_DIRTY,
    }),
    applyLimitToAllSelectedRoles(limitToApply) {
      this.roleStructure.items.forEach((item) => {
        if (item.selected) {
          item.limits.forEach((limit) => {
            limit.value = limitToApply.value;
          });
        }
      });

      this.resetRoleStructure();
      this.updateProduct();
    },
    populateRoleStructure() {
      const featuredProductRoles = this.featuredProductRoles;
      this.roleStructure.items = this.roles.map((r) => {
       const isFeaturedItem = featuredProductRoles.find(fi => fi == r.id);
        return {
          userRoleId: r.id,
          name: r.name,
          selected: false,
          isFeaturedItem: isFeaturedItem!=null,
          limits: [],
        };
      });

      this.setSelectedRoles();
    },
    resetRoleStructure() {
      this.roleStructure = {
        ...this.roleStructure,
      };
    },
    selectAllRoles() {
      const deselected = this.roleStructure.items.filter((i) => !i.selected);

      deselected.forEach((d) => {
        d.selected = true;

        if (!d.limits || d.limits.length === 0) {
          d.limits = this.limitTypes.map((type) => {
            return { code: type.id, value: null };
          });
        }
      });

      this.updateProduct();
      this.resetRoleStructure();
    },
    setSelectedRoles() {
      this.roleStructure.items.forEach((i) => {
        const assignedRole = this.product.roles.find(
          (r) => r.userRoleId === i.userRoleId
        );

        if (assignedRole) {
          i.selected = true;
          if(assignedRole.attributes) {
            assignedRole.attributes.Limits.forEach((limit) => {
              i.limits.push({
                code: limit.limitCode,
                value: limit.limitValue,
              });
            });
          }
        }
      });
    },
    toggleFeatureItem(role) {
         const roleToToggle = this.roleStructure.items.find((r) => {
          return r.userRoleId === role.userRoleId;
        });
        roleToToggle.isFeaturedItem = !role.isFeaturedItem;
        this.resetRoleStructure();
        this.updateProduct();
    },
    toggle(role) {
      if (role) {
        const roleToToggle = this.roleStructure.items.find((r) => {
          return r.userRoleId === role.userRoleId;
        });

        if (roleToToggle) {
          if (roleToToggle.selected) {
            roleToToggle.selected = false;
            roleToToggle.limits = [];
          } else {
            roleToToggle.selected = true;

            if (!roleToToggle.limits || roleToToggle.limits.length === 0) {
              roleToToggle.limits = this.limitTypes.map((type) => {
                return { code: type.id, value: null };
              });
            }
          }
        }

        this.resetRoleStructure();
      }

      this.updateProduct();
    },
    updateLimitValue(role) {
      role.limits.forEach((limit) => {
        if (limit.value === "") limit.value = null;
      });

      this.updateProduct();
    },
    updateProduct() {
      this.setProductPropertyImmediate({ name: "availability", value: this.selected });

      if (this.selected === ProductAvailability.ROLE_BASED) {
        const roles = [];
        const selectedRoles = this.roleStructure.items.filter(
          (i) => i.selected
        );
        let selectedFeatureItemRoles = [];
        if (selectedRoles && selectedRoles.length > 0) {
          selectedRoles.forEach((sr) => {
            const limits = sr.limits.map((lim) => {
              return { limitCode: lim.code, limitValue: lim.value };
            });
            if(sr.isFeaturedItem){
              selectedFeatureItemRoles.push(sr.userRoleId);
            }
            roles.push({
              userRoleId: sr.userRoleId,
              attributes: { Limits: limits},
            });
          });
        }

        let featuredItemsRoles = {
            tagName: TagNames.FEATURED
        }
        if(selectedFeatureItemRoles && selectedFeatureItemRoles.length > 0){
          this.setProductPropertyImmediate({ name: "featuredProductUserRoleIds", value: selectedFeatureItemRoles, attribute: ATTRIBUTE_NAME });
          featuredItemsRoles.userRoleIds = selectedFeatureItemRoles;
        } else {
            featuredItemsRoles.userRoleIds = []
        }
        this.setProductPropertyImmediate({ name: "featureItemRoles", value: featuredItemsRoles, attribute: ATTRIBUTE_NAME });
        this.setProductProperty({ name: "roles", value: roles });
      } else {
        this.setProductProperty({ name: "roles", value: [] });
      }
    },
    getLimitText(value) {
        if (value == "month") {
            return "Monthly"
        }
        return value;
    }
  },
  created() {
    this.populateRoleStructure();
  },
  mounted() {
    if (!this.rolesConfig.options.hasGuestUsers) {
      this.selected = ProductAvailability.ROLE_BASED;
    } else {
      this.selected = this.product.availability;
    }

    this.setProductPropertyImmediate = this.setProductProperty;
    this.setProductProperty = this.$nucleus.debounce(
      this.setProductProperty,
      500
    );
  },
  watch: {
    roleStructure: {
      deep: true,
      handler: function() {
        this.updateProduct();
      },
    },
    selected: {
      deep: true,
      handler: function() {
        this.updateProduct();

        if (this.firstWatch) {
          this.setIsProductEditDirty(false);
          this.firstWatch = false;
        }
      },
    },
  },
};
</script>

<style lang="scss" scoped>
label {
  display: inline;
}

.ml30 {
  margin-left: 30px;
}

.pt15 {
  padding-top: 15px;
}

.n-role-row {
  border-bottom: 1px solid $gray-1;
}

.col-form-label {
  padding: 0;
  font-size: 12px;
  font-weight: 700;
}

.n-role-name {
  font-size: 16px;
}

.n-question-icon {
  color: $primary-dark-color;
}

.selected {
  background: $neutral-lightest;
}
</style>
